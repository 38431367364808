*,
*::after,
*::before {
    box-sizing: border-box;
}

ul {
    list-style: none;
}

* {
    padding: 0;
    margin: 0;
}

body {
    background-image: url('../media/background1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
}

/* Header css here */

.header {
    padding-top: 51px;
    padding-left: 14px;
    width: 180px;
}

.header img { 
    width: 100%;
}

@media screen and (max-width: 768px) {
    .header {
        height: 100px;
    }
}

/* footer */

.footer {
    position: fixed;
    bottom: 0;
    z-index: 1;
    background: #F5F0EB;
    box-shadow: 0px -4px 7px -1px rgba(0, 0, 0, 0.1);
    height: 84px;
    width: 100%;
}

.next-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.next-btn {
    background: #D9D9D9;
    border-radius: 6px;
    padding: 16px;
    width: 545px;
    border: none;
    font-family: 'Object Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 116.5%;
    color: #FFF7F7;
    cursor: pointer;
}

.active {
    background: #3282FF;
}

@media screen and (max-width: 768px) {
    .footer {
        height: 109px;
        background: #FFFFFF;
    }

    .next-btn {
        width: 342px;
        background: rgba(0, 0, 0, 0.14);
        padding: 14px;
        font-size: 22px;
        line-height: 116.5%;
        color: #FFF7F7;
    }

    .active {
        background: #3282FF;
    }
}

/* Success page */
.success-wrapper {
    width: 100vw;
    height: calc(100vh - 184px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-content-wrapper {
    text-align: center;
}

.success-content-wrapper h3 {
    font-family: 'Object Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 132%;
    color: #FFFFFF;
    margin-bottom: 18px;
}

.success-img {
    width: 62px;
    height: 62px;
    margin-bottom: 24px;
}

/* login page */

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 0;
}

.login-box {
    background: #F5F0EB;
    border-radius: 6px;
    width: 65%;
    max-width: 650px;
    padding: 30px;
}

.login-box h2 {
    font-family: 'Mars Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 96.5%;
    color: #000023;
    text-align: center;
}

.login-box h4 {
    font-style: normal;
    font-weight: 400;
    color: #000023;
    margin-top: 16px;
    text-align: center;
}

.login-box h2 span {
    color: #3282FF;
}

.login-form {
    margin-top: 56px;
}

.login-form input,
.login-form textarea {
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 0.74px solid #000000;
    padding: 12px 0;
    outline: none;
    font-size: 18px;
    font-family: 'Object Sans';
}

select.input-block {
    color: #757575;
}

.login-form label {
    font-family: 'Object Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 96.5%;
    color: #000000;
}

.hidden-option {
    color: #ccc;
}

.input-block {
    margin-bottom: 40px;
}

.input-block-wrapper {
    display: flex;
    justify-content: space-between;
}

.input-wrapper {
    width: 45%;
}

option {
    padding: 12px;
}

.submit-btn {
    font-family: 'Object Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 116.5%;
    text-align: center;
    color: #FFF7F7;
    border-radius: 6px;
    border-bottom: none !important;
    cursor: pointer;
}

.disable {
    background: #D9D9D9 !important;
}

.active {
    background: #3282FF !important;
}

@media screen and (max-width: 768px) {

    .login-wrapper {
        padding: 40px 0px;
        /* background-color: #F5F0EB; */
        margin-bottom: 51px;
    }

    .success-wrapper {
        height: calc(100vh - 109px);
    }

    .input-wrapper {
        width: 100%;
    }

    .login-box {
        background: none;
        border-radius: 0;
        padding: 24px;
        width: 100%;
    }

    .login-box h2 {
        font-size: 36px;
        color: #fff;
    }

    .login-box h2 span {
        color: #fff;
    }

    .login-box h4 {
        font-size: 14px;
        color: #fff;
    }

    .mb-50 {
        margin-bottom: 50px;
    }

    .input-block-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

    }

    .login-form {
        margin-top: 48px;
    }

    .input-block {
        margin-bottom: 50px;
    }

    .login-form label {
        font-size: 13px;
        color: #fff;
    }

    .login-form input,
    .login-form textarea {
        font-size: 14px;
        color: #fff;
    }

    .login-form input::placeholder,
    .login-form textarea::placeholder {
        color: #f7f7f7a1;
    }
}

.ratingContainer {
    display: flex;
    margin-top: 14px;
}

.ratingLabel {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ratingContainer input[type="radio"] {
    opacity: 0;
    position: absolute;
    width: 60px;
}

.ratingContainer input[type="radio"]+label::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid #3282FF;
    border-radius: 50%;
}

.ratingContainer input[type="radio"]:checked+label::before {
    background-color: #3282FF;
}

.placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #3282FF;
}

.pActive {
    color: white;
}

@media screen and (max-width: 768px) {
    .ratingLabel {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }

    .ratingContainer input[type="radio"]+label::before {
        border: 1px solid #ffffff;
    }

    .placeholder {
      color: #fff;
    }

    
.ratingContainer input[type="radio"]:checked+label::before {
    background-color: #fff;
}
    
    .pActive {
        color: #3282FF;
    }

}
