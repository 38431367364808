body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Mars Condensed';
  src: url('./assets/font/Mars_Condensed-/Mars_Condensed/Desktop_license/Mars/marscondensed-regular.otf') format('otf'),
  url('./assets/font/Mars_Condensed-/Mars_Condensed/Web_license/Mars/marscondensedweb-regular.woff') format('woff')
}

@font-face {
  font-family: 'Object Sans';
  src: url('./assets/font/Object_Sans_Regular/Object_Sans_Regular/OTF/ObjectSans-Regular.otf') format('otf'),
  url('./assets/font/Object_Sans_Regular/Object_Sans_Regular/TTF/ObjectSans-Regular.ttf') format('ttf'),
  url('./assets//font/Object_Sans_Regular/Object_Sans_Regular/WEB/ObjectSans-Regular.woff') format('woff')
}
